import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'single-column',
  templateUrl: './single-column.component.html',
  styleUrls: ['./single-column.component.scss']
})
export class SingleColumnComponent implements OnInit {

  @Input() terms: boolean = true;
  @Input() talk: boolean = true;
  @Input() privacy: boolean = true;
  @Input() doNotSell: boolean = true;
  @Input() lookingForTruebill: boolean = false;

  displayTOS: boolean = false;
  truebillLink: string = environment.lookingForTruebillLink;

  constructor() { }

  ngOnInit() { }

  toggleTOS() {
    this.displayTOS = !this.displayTOS;
  }
}
