<single-column>
  <div class="rkt-TextAlign--center" *ngIf="cancelDeleteModal && !cancelled && !isComplete">
    <h1 class="rkt-Heading-48 rkt-Heading-48--responsive">Before we cancel your account deletion request.</h1>
    <img src="/assets/img/Email-Unverified.svg" class="center-block">
  </div>
  <div class="rkt-TextAlign--center" *ngIf="spinnerOn && !cancelDeleteModal && !cancelled && !isComplete">
    <h1 class="rkt-Heading-48 rkt-Heading-48--responsive">Please wait while we cancel your account deletion request.</h1>
    <div class="spinner">
      <mat-spinner
        aria-label="spinner label"
        diameter="90"
        class="rkt-ProgressSpinner">
      </mat-spinner>
    </div>
    <img src="/assets/img/Email-Unverified.svg" class="center-block">
  </div>
  <div class="rkt-TextAlign--center" *ngIf="cancelled && isComplete">
    <h1 class="rkt-Heading-48 rkt-Heading-48--responsive">Success! Your request for account deletion has been cancelled.</h1>
    <img src="/assets/img/Email-Success.svg" class="center-block">
  </div>
  <div class="rkt-TextAlign--center" *ngIf="noDeletionReq && !cancelled && isComplete">
    <h1 class="rkt-Heading-48 rkt-Heading-48--responsive">Deletion Request Not Found!</h1>
    <p class="rkt-Body-16">No deletion request currently exists for your account. Please <a routerLink="/talk-to-us">contact us</a> if you have any questions.</p>
    <img src="/assets/img/Email-Error.svg" class="center-block">
  </div>
  <div class="rkt-TextAlign--center" *ngIf="genericError && !cancelled && isComplete">
    <h1 class="rkt-Heading-48 rkt-Heading-48--responsive">Generic Error</h1>
    <p class="rkt-Body-16">Uh oh! Something isn't working. Please try to <a routerLink="/talk-to-us">contact us</a>.</p>
    <img src="/assets/img/Email-Error.svg" class="center-block">
  </div>
</single-column>

<app-modal *ngIf="cancelDeleteModal" width="580">
  <p class="rkt-TextAlign--center rkt-Body-16">Are you sure you want to cancel the deletion request for your account?</p>
  <div class="buttons">
      <button class="rkt-Button" id="modalCancel" (click)="closeModal()" mat-flat-button color="primary">No</button>
      <button class="rkt-Button" id="modalSubmit" (click)="onSubmit()" mat-flat-button color="primary">Yes</button>
  </div>
</app-modal>
