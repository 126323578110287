import { Component, OnInit } from '@angular/core';
import { PostTokenService } from 'app/services/logical/post-token.service';
import { Router} from '@angular/router';
import { CancelDeleteService } from 'app/services/logical/cancel-delete.service';
import { readCookie } from 'app/util/cookie';
import { AnalyticsService } from 'app/services/generic/analytics.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UtilModule } from 'app/util/util.module';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    UtilModule,
    MatProgressSpinnerModule,
    CommonModule,
    MatButtonModule
  ],
  selector: 'app-email-confirm',
  templateUrl: './cancel-delete-request.component.html',
  styleUrls: ['./cancel-delete-request.component.scss'],
  providers: [PostTokenService, CancelDeleteService, AnalyticsService, Router]
})
export class CancelDeleteRequestComponent implements OnInit {

  isValid = true;
  isComplete = false;
  redirect?: string;
  cancelled = false;
  genericError = false;
  expired = false;
  notFound = false;
  errorCode?: number;
  errMsg?: string;
  noDeletionReq: boolean = false;
  cancelDeleteModal: boolean = false;
  alreadyCancelled: boolean = false;
  spinnerOn: boolean = false;

  constructor(
    private _router: Router,
    private _cancelDelete: CancelDeleteService,
    private _analytics: AnalyticsService
    ) {}

  ngOnInit() {
    this._analytics.pageViewEvent("Cancel Delete Request");
    if(sessionStorage.getItem('successSignedIn')){
      this.cancelDeleteModal = true;
    }
    else if(sessionStorage.getItem('cancelledDeleteRequest')){
      this.alreadyCancelled = true;
    }
    else{
      this._router.navigateByUrl('sign-in?from=cancelDelete');
    }
  }

  openModal() {
    this.cancelDeleteModal = true;
  }

  closeModal() {
    this.cancelDeleteModal = false;
    this._router.navigateByUrl('success-landing');
  }

  hideSpinner() {
    this.spinnerOn = false;
  }

  onSubmit() {
    this.spinnerOn = true;
    this.cancelDeleteModal = false;
    setTimeout(() => {this.cancelDelete()}, 3000);
  }

  cancelDelete() {
    this._cancelDelete.cancelDeleteSubmit()
    .subscribe({
      next: data => {
          this.cancelled = true;
          sessionStorage.setItem('cancelledDeleteRequest', 'true');
      },
      error: error => {
          this.cancelled = false;
          this.isComplete = true;
          this.errMsg = error.message
          if(error.status === 400){
            this.noDeletionReq = true;
            setTimeout(() => this._router.navigateByUrl('success-landing'), 3000);
          }else{
            this.genericError = true;
          }
        },
      complete: () => {
          this.isComplete = true;
          setTimeout(() => this.hideSpinner(), 3000);
          setTimeout(() => this._router.navigateByUrl('success-landing'), 3000);
        }
      });
    }
}
