import { Component, OnInit } from '@angular/core';
import { LogoScriptService } from 'app/services/generic/logo-script.service';
import { SplitService } from 'app/modules/feature-flags/services/split.service';

@Component({
  standalone: true,
  selector: 'logo-block',
  templateUrl: './logo-block.component.html',
  styleUrls: ['./logo-block.component.scss']
})
export class LogoBlockComponent implements OnInit {
  
  constructor(
    private logoScriptService: LogoScriptService
  ) {
  }

  ngOnInit() {
    this.logoScriptService.logoScript(window, document, "body {opacity: 0 !important}");
  }
}
