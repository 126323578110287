import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

let head = document.getElementsByTagName('head')[0];
let reference = head.childNodes[0];

/**
 * Adobe Launch script load
 */

const adobeLaunchScript = document.createElement('script');
head.insertBefore(adobeLaunchScript, reference);
adobeLaunchScript.type = 'text/javascript';
adobeLaunchScript.src = environment.adobeLaunchUrl;
adobeLaunchScript.async = true;


bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
