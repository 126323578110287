import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class CancelDeleteService {

  constructor(private _http: HttpClient) { }

  cancelDeleteSubmit() {
    const headers = new HttpHeaders({
      'Content-Type': environment.headers.standard,
      'Accept': environment.headers.standard,
    });
    
    return this._http.post(environment.apiConsumerBaseV2 + '/current/account/cancel-delete', {}, {headers: headers})
  }

}
