import { Routes } from '@angular/router';

import { PageNotFoundComponent} from 'app/components/page-not-found/page-not-found.component';
import { CreateAccountFormComponent } from 'app/components/create-account-form/create-account-form.component';
import { CancelDeleteRequestComponent } from 'app/components/cancel-delete-request/cancel-delete-request.component';
import { TalkToUsComponent } from 'app/components/talk-to-us/talk-to-us.component';
import { MetricsResolver } from 'app/routers/metrics/metrics.resolver';
import { TosComponent } from "app/util/tos/tos.component";
import { DoNotSellComponent } from 'app/components/do-not-sell/do-not-sell.component';
import { PrivacyPolicyComponent } from 'app/components/privacy-policy/privacy-policy.component';
import { PrivacyPolicySpanishComponent } from 'app/components/privacy-policy-spanish/privacy-policy-spanish.component';
import { ErrorComponent } from 'app/components/error/error.component';
import { EmailVerifyComponent } from 'app/components/email-verify/email-verify.component';
import { RedirectGuard } from './routers/redirect/redirect.guard';

export const routes: Routes = [
    { path: 'create-account', resolve: [ MetricsResolver ], component: CreateAccountFormComponent },
    { path: 'talk-to-us', component: TalkToUsComponent },
    { path: 'change-username/claim/:username-token', component: EmailVerifyComponent },
    { path: 'cancel-delete', component: CancelDeleteRequestComponent },
    { path: 'terms-of-use', component: TosComponent },
    { path: 'do-not-sell-my-personal-information', component: DoNotSellComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'privacy-policy-spanish', component: PrivacyPolicySpanishComponent },
    { path: 'error', component: ErrorComponent },
    { path: '**', canActivate: [RedirectGuard], component: PageNotFoundComponent },
];
