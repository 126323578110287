<div>
    <!-- Web view, when Rocket Solar flag is not active -->
    <div class="logos logos-standard logos-standard--first-row" id="standard-logo-block">
        <img src="/assets/img/logo/RM-logo.svg" id="rmLogo" alt="Rocket Mortgage">
        <img src="/assets/img/logo/RH-logo.svg" id="rhLogo" alt="Rocket Homes">
    </div>
    <div class="logos logos-standard logos-standard--second-row" id="standard-logo-block">
        <img src="/assets/img/logo/RL-logo.svg" id="rlLogo" alt="Rocket Loans">
        <img src="/assets/img/logo/RMN-logo.svg" id="rmnLogo" alt="Rocket Money">
  
    </div>
  
    <!-- Mobile view, when Rocket Solar flag is not active but Rocket Money flag is active -->
    <div class="logos-mobile--first-row" id="mobile-logo-block">
        <img src="/assets/img/logo/RM-logo.svg" id="rmLogo" alt="Rocket Mortgage">
        <img src="/assets/img/logo/RH-logo.svg" id="rhLogo" alt="Rocket Homes">
    </div>
    <div class="logos-mobile--second-row" id="mobile-logo-block">
        <img src="/assets/img/logo/RL-logo.svg" id="rlLogo"  alt="Rocket Loans">
        <img src="/assets/img/logo/RMN-logo.svg" id="rmnLogo" alt="Rocket Money">
    </div>
  </div>
  