import { Injectable } from '@angular/core';

// declare webkit variable for use with iOS webviews
declare global {
  interface Window {
    webkit: any;  // event hook for iOS
    Android: any; // event hook for Android
  }
}

@Injectable()
export class AppHookService {

  private userAgent = window.navigator.userAgent;

  constructor() { }

  get isWebView() {
    return (window.webkit && !this.isChromeiOS) || (window.Android);
  }

  get isChromeiOS() {
    return this.userAgent.includes('CriOS');
  }

  messageApp(success: boolean, route: string, data: any, error?: any) {
    let payload: any = {
      success: success,
      route: route,
      data: data,
    };
    if (error) {
      payload['error'] = error;
    }
    this.messageiOS(payload);
    this.messageAndroid(JSON.stringify(payload));
  }

  private messageAndroid(data: any) {
    if (window.Android) {
      window.Android.webViewListener(data);
    }
  }

  private messageiOS(data: any) {
    if (window.webkit && !this.isChromeiOS) {
      window.webkit.messageHandlers.webViewListener && window.webkit.messageHandlers.webViewListener.postMessage(data);
    }
  }
}
