import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withHashLocation, withInMemoryScrolling } from '@angular/router';
import { InjectionToken } from '@angular/core';
import { HttpClient, provideHttpClient } from '@angular/common/http';

// Services
import { RedirectService } from 'app/services/generic/redirect.service';
import { AppHookService } from 'app/services/app-hook.service';
import { MetricsService } from 'app/services/metrics.service';
import { AnalyticsService } from 'app/services/generic/analytics.service';
import { SendEmailVerification } from 'app/services/logical/send-email-verification.service';
import { ScriptService } from 'app/services/script.service';
import { MetricsResolver } from 'app/routers/metrics/metrics.resolver';
import { RedirectGuard } from 'app/routers/redirect/redirect.guard';
import { provideAuth0 } from '@auth0/auth0-angular';
import { environment } from 'environments/environment';

import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AccountService } from './services/logical/account.service';

const APP_ID: InjectionToken<string> = new InjectionToken<string>('ra-universal');

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes, 
      withHashLocation(), 
      withInMemoryScrolling({ anchorScrolling: 'enabled' })
    ),
    provideAuth0({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      cacheLocation: 'localstorage',
      useRefreshTokens: true,
      authorizationParams: {
          redirect_uri: environment.auth0.redirectUri,
          scope: 'openid profile email offline_access',
          audience: environment.auth0.audience,
      }
    }),
    AnalyticsService,
    AccountService,
    MetricsService,
    RedirectService,
    AppHookService,
    SendEmailVerification,
    ScriptService,
    MetricsResolver,
    RedirectGuard,
    { provide: APP_ID,  useValue: 'ra-universal' },
    HttpClient,
    provideHttpClient(),
    provideAnimations(),
    provideZoneChangeDetection({ eventCoalescing: true }), 
  ]
};
