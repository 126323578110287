import { splitApiFactory } from '@splitsoftware/splitio-commons/esm/services/splitApi';
import { syncManagerOnlineFactory } from '@splitsoftware/splitio-commons/esm/sync/syncManagerOnline';
import { pushManagerFactory } from '@splitsoftware/splitio-commons/esm/sync/streaming/pushManager';
import { pollingManagerCSFactory } from '@splitsoftware/splitio-commons/esm/sync/polling/pollingManagerCS';
import { sdkManagerFactory } from '@splitsoftware/splitio-commons/esm/sdkManager/index';
import { sdkClientMethodCSFactory } from '@splitsoftware/splitio-commons/esm/sdkClient/sdkClientMethodCS';
import { BrowserSignalListener } from '@splitsoftware/splitio-commons/esm/listeners/browser';
import { impressionObserverCSFactory } from '@splitsoftware/splitio-commons/esm/trackers/impressionObserver/impressionObserverCS';
import { pluggableIntegrationsManagerFactory } from '@splitsoftware/splitio-commons/esm/integrations/pluggable';
import { CONSUMER_MODE, CONSUMER_PARTIAL_MODE, LOCALHOST_MODE } from '@splitsoftware/splitio-commons/esm/utils/constants';
import { createUserConsentAPI } from '@splitsoftware/splitio-commons/esm/consent/sdkUserConsent';
var syncManagerStandaloneFactory;
var syncManagerSubmittersFactory;
export function getModules(settings, platform) {
  if (!syncManagerStandaloneFactory) syncManagerStandaloneFactory = syncManagerOnlineFactory(pollingManagerCSFactory, pushManagerFactory);
  var modules = {
    settings: settings,
    platform: platform,
    storageFactory: settings.storage,
    splitApiFactory: splitApiFactory,
    syncManagerFactory: syncManagerStandaloneFactory,
    sdkManagerFactory: sdkManagerFactory,
    sdkClientMethodFactory: sdkClientMethodCSFactory,
    SignalListener: BrowserSignalListener,
    integrationsManagerFactory: settings.integrations && settings.integrations.length > 0 ? pluggableIntegrationsManagerFactory.bind(null, settings.integrations) : undefined,
    impressionsObserverFactory: impressionObserverCSFactory,
    extraProps: function (params) {
      return {
        UserConsent: createUserConsentAPI(params)
      };
    }
  };
  switch (settings.mode) {
    case LOCALHOST_MODE:
      modules.splitApiFactory = undefined;
      modules.syncManagerFactory = settings.sync.localhostMode;
      modules.SignalListener = undefined;
      break;
    case CONSUMER_MODE:
      modules.syncManagerFactory = undefined;
      break;
    case CONSUMER_PARTIAL_MODE:
      if (!syncManagerSubmittersFactory) syncManagerSubmittersFactory = syncManagerOnlineFactory(undefined, undefined);
      modules.syncManagerFactory = syncManagerSubmittersFactory;
  }
  return modules;
}