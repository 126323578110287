import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { animate, state, style, transition, trigger} from '@angular/animations';

const animationSpeed = 300;

@Component({
  selector: 'rckt-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  animations: [
    trigger('remove', [
      state('shown', style({ 'opacity': 1 })),
      state('leaving', style({ 'opacity': 0 })),
      transition('shown => *', [
        animate(`${ animationSpeed }ms ease-in`, style({ 'opacity': 0 }))
      ])
    ])
  ]
})
export class AlertComponent implements AfterViewInit {

  @Input() type?: string;
  @Input() isDismissable?: boolean;
  @Output() closed = new EventEmitter();

  state = 'shown';

  constructor(
    private el: ElementRef) {
      if(!this.type) this.type = 'info';
      if(!this.isDismissable) this.isDismissable = true;
     }

  ngAfterViewInit() {
    this.el.nativeElement.scrollIntoView();
  }

  close() {
    this.state = 'leaving';
    setTimeout(() => this.closed.emit(), animationSpeed);
  }

}
