<div class="rkt-CenteredColumn privacy-policy" id="top">
    <div class="marginDiv">
        <!-- 3/5 stack w/privacy policy -->
        <rkt-stack splitAt="6">
            <div rktStackItem class="rkt-Stack__item--three-fifths@6">
                <!-- rocket logo -->
                <img class="rocket-logo" src="../../../../assets/img/logo/Rocket-Logo.svg">
                <!-- headers and copy -->
                <rkt-stack spacing="8">
                    <div rktStackItem id="rocket-privacy-policy" class="header-title">
                        <h1 class="rkt-Heading-28 rkt-Spacing--mb24">
                            Rocket Family Of Companies Privacy Policy
                        </h1>
                        <!-- print button, shows only at small viewports (under 880px) -->
                        <div class="print-button--container print-button--narrow" *ngIf="showTableOfContents">
                            <button class="rkt-Button rkt-Button--secondary" mat-stroked-button color="accent"
                                id="print-button" (click)="printPrivacyPolicy()">Print privacy policy</button>
                        </div>
                        <p class="rkt-Body-16 rkt-Spacing--mb24"><a rktLink href="#/privacy-policy-spanish">Política de
                                privacidad de la familia de compañías Rocket</a></p>
                        <p class="rkt-Body-16 rkt-Spacing--mb24"><b>Effective Date</b>: January 8, 2024</p>
                        <p class="rkt-Body-16 rkt-Spacing--mb24">
                            This Rocket Family of Companies Privacy Policy describes how the Rocket Family of Companies
                            (as defined in
                            <a rktLink href="#/privacy-policy#who-we-are">"Who We Are"</a> below) process information
                            collected on our websites and
                            applications (including mobile) or through your online interactions with us (collectively,
                            our "Services").
                        </p>
                        <p class="rkt-Body-16 rkt-Spacing--mb24">
                            In some circumstances, we may provide you with additional or supplemental privacy notices to
                            the extent they apply to you because of the
                            products and services you obtain from us or different laws that may apply to you. Some
                            states may provide additional privacy rights. Please refer to the
                            <a rktLink href="#/privacy-policy#additional-information">Additional Information Regarding
                                Your State Privacy Rights</a> section to learn more.
                        </p>
                        <p class="rkt-Body-16 rkt-Spacing--mb24">
                            <b>
                                By using any of our Services, you acknowledge the privacy practices we describe in this
                                Rocket Family of Companies Privacy Policy. You
                                also acknowledge that you have read this policy and other policies described.
                            </b>
                        </p>
                    </div>
                    <div class="rkt-Spacing--mb24"><mat-divider
                        class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                    <!-- "Who We Are" section -->
                    <div rktStackItem id="who-we-are">
                        <h1 class="rkt-Heading-36 rkt-Spacing--mb24">Who We Are</h1>
                        <p class="rkt-Body-14 rkt-Spacing--mb24">
                            When we say "<b>Rocket Family of Companies</b>," we mean companies that are controlled by
                            RKT Holdings, LLC and share the Rocket name
                            or are on the Rocket platform. A reference to "Rocket", "we" or "us" is a reference to the
                            relevant Rocket Company in the Rocket Family of
                            Companies that is involved in the processing activity. This policy does not apply to Rocket
                            affiliates that aren't in the Rocket Family of
                            Companies ("Affiliates"). The data practices of Affiliates are governed by their own privacy
                            policies.
                        </p>
                        <div rktStackItem>
                            <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider>
                            </div>
                        </div>
                        <mat-accordion class="rkt-Spacing--mb24">
                            <mat-expansion-panel class="rkt-AccordionPanel rkt-Spacing--mb24"
                                idString="rocket-family-of-companies" [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="rkt-AccordionPanel__header-title">
                                        <h3 class="rkt-Heading-28">Rocket Family Of Companies</h3>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="rkt-AccordionPanel__content">
                                    <p class="rkt-Body-14 rkt-Spacing--mb8">The Rocket Family of Companies includes:</p>
                                    <ul>
                                        <li>
                                            <p class="rkt-Body-14">Rocket Mortgage</p>
                                        </li>
                                        <li>
                                            <p class="rkt-Body-14">Rocket Loans</p>
                                        </li>
                                        <li>
                                            <p class="rkt-Body-14">Rocket Homes</p>
                                        </li>
                                        <li>
                                            <p class="rkt-Body-14">Rocket Money, Inc. (f/k/a Truebill, Inc.) (“Rocket
                                                Money”), a Rocket Company</p>
                                        </li>
                                        <li>
                                            <p class="rkt-Body-14">Rocket Central</p>
                                        </li>
                                        <li>
                                            <p class="rkt-Body-14">ForSaleByOwner.com, a Rocket Company</p>
                                        </li>
                                        <li>
                                            <p class="rkt-Body-14">Rocket Card</p>
                                        </li>
                                    </ul>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <div class="rkt-Spacing--mb24"></div>
                        <div class="rkt-Spacing--mb24"></div>
                        <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Back to Top</a>
                    </div>
                    <div class="rkt-Spacing--mb24"><mat-divider
                            class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                    <!-- "Information We Collect" section -->
                    <div rktStackItem id="information-we-collect">
                        <h1 class="rkt-Heading-36 rkt-Spacing--mb8">Information We Collect</h1>
                        <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                        <mat-accordion>
                            <mat-expansion-panel class="rkt-AccordionPanel" idString="information-you-provide"
                                [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="rkt-AccordionPanel__header-title">
                                        <h3 class="rkt-Heading-28">Information You Provide</h3>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">We collect information you provide to us. For
                                    example, we collect information from you when you create a Rocket account, fill out
                                    a
                                    form or otherwise submit content through our Services, are referred to us via one of
                                    our third-party professional partner relationships,
                                    make a purchase, communicate with us via third-party platforms, participate in a
                                    contest, promotion or survey, request customer
                                    support or information about our Services, or otherwise communicate with us.
                                </p>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    The specific information we collect depends on the context in which you provide it,
                                    and could include:
                                </p>
                                <ul>
                                    <li>Name</li>
                                    <li>Email address</li>
                                    <li>Postal address</li>
                                    <li>Phone number</li>
                                    <li>Social security number</li>
                                    <li>Location and approximate value of your property</li>
                                    <li>Vehicle information, such as make, model, VIN and vehicle features</li>
                                    <li>Age and/or date of birth</li>
                                    <li>Demographic information, such as race, ethnicity and gender gathered pursuant to
                                        federal requirements</li>
                                    <li>Related loan products or services you have used in the past</li>
                                    <li>Financial information, such as income, assets and net worth</li>
                                    <li>Home ownership status and employment status</li>
                                    <li>Insurance information</li>
                                    <li>Other profile data, including your interests and preferences</li>
                                    <li>Any other information you choose to provide</li>
                                </ul>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <div class="rkt-Spacing--mb24"></div>
                        <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                        <mat-accordion>
                            <mat-expansion-panel class="rkt-AccordionPanel"
                                idString="information-we-automatically-collect" [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="rkt-AccordionPanel__header-title">
                                        <h3 class="rkt-Heading-28">Information We Automatically Collect</h3>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">We automatically collect certain information
                                    about your interactions with us or our Services, including:</p>
                                <ul>
                                    <li>
                                        <p class="rkt-Body-14 rkt-Spacing--mb8"><b>Transactional Information: </b>When
                                            you make a purchase or complete a transaction, we collect information about
                                            the transaction,
                                            such as product or service details, financial details and the date and
                                            location of the transaction.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="rkt-Body-14 rkt-Spacing--mb8"><b>Device and Usage Information: </b>We
                                            collect information about how you access our Services, including data about
                                            the device and
                                            network you use, such as hardware model, operating system version, mobile
                                            network, IP address, unique device identifiers and device
                                            regional and language settings, browser type, and app version. We also
                                            collect information about your activity on our Services, such
                                            as access times, pages viewed, links clicked, products and services
                                            considered, and the page you visited before navigating to our
                                            Services.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="rkt-Body-14 rkt-Spacing--mb8"><b>Your Content: </b>As you allow
                                            through your device or application, we may collect information and content
                                            stored on your device,
                                            such as photos.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="rkt-Body-14 rkt-Spacing--mb8"><b>Location Information: </b>In
                                            accordance with your device permissions, we may collect information about
                                            the precise location of
                                            your device. You may stop the collection of precise location information at
                                            any time (see the
                                            <a rktLink href="#/privacy-policy#your-choices">Your Choices</a> section
                                            below for details).
                                        </p>
                                    </li>
                                    <li>
                                        <p class="rkt-Body-14 rkt-Spacing--mb8"><b>Information Collected by Cookies and
                                                Similar Tracking Technologies: </b> We (and those who perform work for
                                            us) use tracking
                                            technologies, such as cookies and web beacons, to collect information about
                                            your interactions with the Services.
                                        </p>
                                        <ul>
                                            <li>
                                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                                    <b>Cookies</b> are small data files stored on your hard drive or in
                                                    device memory that help us improve our Services and your
                                                    experience, see which areas and features of our Services are popular
                                                    and which emails and advertisements you view, authenticate
                                                    users, reconstruct or observe activity relating to a session or by a
                                                    user for troubleshooting, issue resolution, and other
                                                    purposes, and count visits to our websites.
                                                </p>
                                            </li>
                                            <li>
                                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                                    <b>Web beacons</b> (also known as <b>"pixel tags"</b> or <b>"clear
                                                        GIFs"</b>) are electronic images that we use on our Services and
                                                    in our emails to help deliver cookies, count visits, and understand
                                                    usage and campaign effectiveness. In our mobile apps, we
                                                    may also use technologies that are not browser-based like cookies.
                                                </p>
                                            </li>
                                            <li>
                                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                                    For example, our apps may include <b>software development kits</b>
                                                    (known as SDKs) which are code that sends information
                                                    about your use to a server. These SDKs allow us to track our
                                                    conversions, bring you advertising both on and off the Service,
                                                    and provide you additional functionality. <b>For more information
                                                        about these tracking technologies and how to disable them,
                                                        see the <a rktLink
                                                            href="#/privacy-policy#advertising-and-analytics">Advertising
                                                            and Analytics</a> section below.</b>
                                                </p>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <div class="rkt-Spacing--mb24"></div>
                        <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                        <mat-accordion>
                            <mat-expansion-panel class="rkt-AccordionPanel" idString="information-we-collect"
                                [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="rkt-AccordionPanel__header-title">
                                        <h3 class="rkt-Heading-28">Information We Collect From Other Sources</h3>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    We obtain information from third-party sources in certain situations. For example,
                                    we may collect information about you from identity
                                    or other verification services, credit bureaus (including your credit report),
                                    advertising networks, mailing list providers, service
                                    providers and data brokers. Our Services also may allow or require you to integrate
                                    or connect your Rocket account with third-party
                                    services through service providers (such as Plaid).
                                </p>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    The information we receive from such third parties varies depending on the
                                    information made available by those entities. Examples of
                                    the types of information we receive include financial account information,
                                    information about account balance, information about
                                    credit accounts, information about loan accounts, information about investment
                                    accounts, identifiers and information about account
                                    owners, information about account transactions and employment information.
                                </p>

                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    Additionally, if you create or log into your Rocket account through a third-party
                                    platform, such as Facebook or Google, we'll have
                                    access to certain information from that platform, such as your name, birthday, and
                                    profile picture, in accordance with the authorization
                                    procedures determined by such platform.
                                </p>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <div class="rkt-Spacing--mb24"></div>
                        <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                        <mat-accordion>
                            <mat-expansion-panel class="rkt-AccordionPanel" idString="information-we-derive"
                                [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="rkt-AccordionPanel__header-title">
                                        <h3 class="rkt-Heading-28">Information We Derive</h3>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    We may derive information or draw inferences about you based on the information we
                                    collect. For example, we may make inferences about
                                    your location based on your IP address or city or postal code or infer that you are
                                    looking to purchase certain products based on
                                    information you provide to us and your browsing behavior and past purchases.
                                </p>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <div class="rkt-Spacing--mb24"></div>
                        <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                        <mat-accordion>
                            <mat-expansion-panel class="rkt-AccordionPanel" idString="plaid-technologies"
                                [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="rkt-AccordionPanel__header-title">
                                        <h3 class="rkt-Heading-28">Plaid Technologies</h3>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    Rocket enables you to use Plaid Technologies, Inc. to gather data from financial
                                    institutions. By using the Services, you acknowledge
                                    and agree that your information will be processed in accordance with the
                                    <a rktLink href="https://plaid.com/legal/#consumers" target="blank">Plaid Privacy
                                        Policy</a> and you grant Rocket and Plaid the same rights,
                                    power and authority as specified therein.
                                </p>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <div class="rkt-Spacing--mb24"></div>
                        <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Back to Top</a>
                    </div>
                    <div class="rkt-Spacing--mb24"><mat-divider
                            class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                    <!-- "How We Use Your Information" section -->
                    <div rktStackItem id="how-we-use">
                        <h1 class="rkt-Heading-36 rkt-Spacing--mb8">How We Use Your Information</h1>
                        <p class="rkt-Body-14 rkt-Spacing--mb8">We use the information we collect to provide our
                            Services to you.</p>
                        <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                        <mat-accordion>
                            <mat-expansion-panel class="rkt-AccordionPanel" idString="other-reasons-to-collect"
                                [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="rkt-AccordionPanel__header-title">
                                        <h3 class="rkt-Heading-28">Other Reasons to Collect Information</h3>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    We also use the information we collect to:
                                </p>
                                <ul>
                                    <li>
                                        Fulfill or deliver the services you requested
                                    </li>
                                    <li>
                                        Provide, maintain, improve and develop new products, services and features,
                                        including debugging and repairing errors in our
                                        Services
                                    </li>
                                    <li>
                                        Personalize your experience with us
                                    </li>
                                    <li>
                                        Send you technical notices, security alerts, support messages and other
                                        transactional or relationship messages, including responding
                                        to your inquiries and comments
                                    </li>
                                    <li>
                                        Communicate with you about products, services, and events offered by Rocket and
                                        others and provide news and information
                                        that we think will interest you. <b>See the <a rktLink
                                                href="#/privacy-policy#your-choices">Your Choices</a> section below for
                                            information
                                            about how to opt out of these communications at any time</b>
                                    </li>
                                    <li>
                                        Monitor and analyze trends, usage, and activities in connection with our
                                        products and services
                                    </li>
                                    <li>
                                        Personalize the advertisements you see on third-party platforms and websites.
                                        <b>For more information, see the
                                            <a rktLink href="#/privacy-policy#advertising-and-analytics">Advertising and
                                                Analytics</a> section below</b>
                                    </li>
                                    <li>
                                        Show you offers for third-party services personalized to you, based on
                                        information we have collected about you
                                    </li>
                                    <li>
                                        Facilitate contests, sweepstakes and promotions, and process and deliver entries
                                        and rewards
                                    </li>
                                    <li>
                                        Detect, investigate, and help prevent security incidents and other malicious,
                                        deceptive, fraudulent, or illegal activity, and
                                        help protect the rights and property of Rocket and others
                                    </li>
                                    <li>
                                        Comply with our legal and financial obligations
                                    </li>
                                    <li>
                                        Create de-identified, anonymized or aggregated information
                                    </li>
                                    <li>
                                        Fulfill any other purpose at your direction
                                    </li>
                                </ul>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <div class="rkt-Spacing--mb24"></div>
                        <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Back to Top</a>
                    </div>
                    <div class="rkt-Spacing--mb24"><mat-divider
                            class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                    <!-- "How We Share Your Information" section -->
                    <div rktStackItem id="how-we-share">
                        <h1 class="rkt-Heading-36 rkt-Spacing--mb8">How We Share Your Information</h1>
                        <p class="rkt-Body-14 rkt-Spacing--mb8">
                            We share personal information in the following circumstances or as otherwise described in
                            this policy.
                        </p>
                        <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                        <mat-accordion>
                            <mat-expansion-panel class="rkt-AccordionPanel"
                                idString="sharing-among-rocket-family-of-companies" [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="rkt-AccordionPanel__header-title">
                                        <h3 class="rkt-Heading-28">Sharing Among Rocket Family Of Companies</h3>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    The <b>Rocket Family of Companies</b> use and share your information with each other
                                    in accordance with this
                                    <b>Rocket Family of Companies Privacy Policy</b>. This allows the <b>Rocket Family
                                        of Companies</b> to manage and provide
                                    and offer you a variety of their services through a broad lifecycle of your personal
                                    and financial needs.
                                </p>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <div class="rkt-Spacing--mb24"></div>
                        <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                        <mat-accordion>
                            <mat-expansion-panel class="rkt-AccordionPanel" idString="sharing-with-affiliates"
                                [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="rkt-AccordionPanel__header-title">
                                        <h3 class="rkt-Heading-28">Sharing With Affiliates</h3>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    Rocket also shares your information with our Affiliates. For example, we share, or
                                    may share, your information:
                                </p>
                                <ul>
                                    <li>
                                        With Affiliates that act as vendors, service providers and consultants that
                                        access personal information to perform services
                                        for us, such as companies that assist us with call center/directory services
                                    </li>
                                    <li>
                                        With Affiliates for their own services and marketing purposes
                                    </li>
                                    <li>
                                        With Affiliates for regulatory reporting purposes and capturing performance
                                        metrics
                                    </li>
                                    <li>
                                        With Affiliates if we believe (1) that your actions are inconsistent with our
                                        user agreements or policies, (2) that you have
                                        violated the law, or (3) it's necessary to protect the rights, property and
                                        safety of Rocket, our users, the public or others
                                    </li>
                                    <li>
                                        In connection with, or during negotiations concerning, any merger, sale of
                                        company assets, financing or acquisition of all or
                                        a portion of our business by another company
                                    </li>
                                    <li>
                                        With Affiliates with your consent or at your direction
                                    </li>
                                </ul>
                                <br>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    We also share aggregated or de-identified information that cannot reasonably be used
                                    to identify you.
                                </p>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <div class="rkt-Spacing--mb24"></div>
                        <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                        <mat-accordion>
                            <mat-expansion-panel class="rkt-AccordionPanel" idString="sharing-with-other-third-parties"
                                [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="rkt-AccordionPanel__header-title">
                                        <h3 class="rkt-Heading-28">Sharing With Other Third Parties</h3>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    Rocket shares your information with non-affiliated third parties in the following
                                    circumstances:
                                </p>
                                <ul>
                                    <li>
                                        We share personal information with vendors, service providers and consultants
                                        that access personal information to perform
                                        services for us, such as companies that assist us with web hosting, shipping and
                                        delivery, payment processing, fraud prevention,
                                        customer service, data analytics, property title and appraisal services,
                                        technical support, marketing and advertising.
                                    </li>
                                    <li>
                                        If you submit a product review or post content in another public area of our
                                        Services, we share this information publicly on
                                        our Services.
                                    </li>
                                    <li>
                                        If you choose to use integrations we offer on our Services, such as sharing your
                                        location through our Google Maps integration,
                                        we may share certain information with the integration partners.
                                    </li>
                                    <li>
                                        We share personal information with third parties for their own services and
                                        marketing purposes, analytics, and in some cases to
                                        jointly market and provide products and services with third parties.
                                    </li>
                                    <li>
                                        When you request information about services offered by other companies, we may
                                        disclose information about you to those companies
                                        so that we or they can notify you of, and determine if you qualify for, products
                                        or services.
                                    </li>
                                    <li>
                                        We may disclose personal information if we believe that disclosure is in
                                        accordance with, or required by, any applicable law or
                                        legal process, including lawful requests by public authorities to meet national
                                        security or law enforcement requirements.
                                    </li>
                                    <li>
                                        We may share personal information if we believe (1) that your actions are
                                        inconsistent with our user agreements or policies,
                                        (2) that you have violated the law, or (3) it's necessary to protect the rights,
                                        property and safety of Rocket, our users, the
                                        public, or others.
                                    </li>
                                    <li>
                                        We share personal information with our lawyers and other professional advisors
                                        where necessary to obtain advice or otherwise
                                        protect and manage our business interests.
                                    </li>
                                    <li>
                                        We may share personal information in connection with, or during negotiations
                                        concerning, any merger, sale of company assets,
                                        financing or acquisition of all or a portion of our business by another company.
                                    </li>
                                    <li>
                                        We share personal information with your consent or at your direction, including
                                        but not limited to through third-party
                                        integrations you choose to enable.
                                    </li>
                                    <li>
                                        We also share aggregated or de-identified information that cannot reasonably be
                                        used to identify you.
                                    </li>
                                    <li>
                                        If you have opted-in or consented to receive text messages, your consent to such
                                        messages will not be shared
                                        with any third parties.
                                    </li>
                                </ul>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <div class="rkt-Spacing--mb24"></div>
                        <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Back to Top</a>
                    </div>
                    <div class="rkt-Spacing--mb24"><mat-divider
                            class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                    <!-- "Advertising and Analytics" section -->
                    <div rktStackItem id="advertising-and-analytics">
                        <h1 class="rkt-Heading-36 rkt-Spacing--mb8">Advertising and Analytics</h1>
                        <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                        <mat-accordion>
                            <mat-expansion-panel class="rkt-AccordionPanel" idString="web-and-mobile-apps"
                                [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="rkt-AccordionPanel__header-title">
                                        <h3 class="rkt-Heading-28">Web and Mobile Apps</h3>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    We share personal information to allow others to provide analytics services and
                                    serve advertisements across the web and in mobile
                                    apps. These entities may use <b>cookies, web beacons, device identifiers</b> and
                                    other technologies to collect information about
                                    your use of our Services and other websites and applications, including your IP
                                    address, web browser, mobile network information,
                                    pages viewed, time spent on pages or in mobile apps, links clicked and conversion
                                    information.
                                </p>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    This information may be used by Rocket and others to, among other things, analyze
                                    and track data, reconstruct or observe activity
                                    relating to a session, determine the popularity of certain content, deliver
                                    advertising and content targeted to your interests on
                                    our Services and other websites, and better understand your online activity.
                                </p>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    <b>
                                        For more information about interest-based ads, or to opt out of having your web
                                        browsing information used for behavioral
                                        advertising purposes, please visit <a rktLink
                                            href="http://www.aboutads.info/choices"
                                            target="blank">www.aboutads.info/choices</a>.
                                    </b>
                                    Your device may also include a feature ("Limit Ad Tracking" on iOS or "Opt Out of
                                    Interest-Based Ads" or "Opt Out of Ads
                                    Personalization" on Android) that allows you to opt out of having certain
                                    information collected through mobile apps used for
                                    behavioral advertising purposes. Certain state privacy laws also provide their
                                    residents with rights relating to submitting a
                                    direct opt-out for the sharing of personal information for purposes of
                                    cross-contextual, or interest-based, advertising.
                                    For more information, please see the Additional Information Regarding Your State
                                    Privacy Rights section below.
                                </p>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <div class="rkt-Spacing--mb24"></div>
                        <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                        <mat-accordion>
                            <mat-expansion-panel class="rkt-AccordionPanel" idString="third-parties" [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="rkt-AccordionPanel__header-title">
                                        <h3 class="rkt-Heading-28">Third Parties</h3>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    We also share personal information to work with third parties to serve ads to you as
                                    part of customized campaigns on third-party
                                    platforms (such as Facebook). As part of these ad campaigns, we or the third-party
                                    platforms may convert information about you,
                                    such as your email address or phone number, into a unique value that can be matched
                                    with a user account on these platforms to
                                    allow us to learn about your interests and serve you advertising that is customized
                                    to your interests. Note that third-party
                                    platforms may offer you choices about whether you see these types of customized ads.
                                </p>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <div class="rkt-Spacing--mb24"></div>
                        <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Back to Top</a>
                    </div>
                    <div class="rkt-Spacing--mb24"><mat-divider
                            class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                    <!-- "Children's Privacy" section -->
                    <div rktStackItem id="childrens-privacy">
                        <h1 class="rkt-Heading-36 rkt-Spacing--mb8">Children's Privacy</h1>
                        <p class="rkt-Body-14 rkt-Spacing--mb8">
                            Rocket products are not intended for children. We don't direct our Services to children
                            under 16, or knowingly attempt to
                            solicit or receive information from children.
                        </p>
                        <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Back to Top</a>
                    </div>
                    <div class="rkt-Spacing--mb24"><mat-divider
                            class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                    <!-- "California Shine the Light" section -->
                    <div rktStackItem id="california-shine-the-light">
                        <h1 class="rkt-Heading-36 rkt-Spacing--mb8">California Shine The Light</h1>
                        <p class="rkt-Body-14 rkt-Spacing--mb8">
                            California law permits residents of California to request certain details about how their
                            information is shared with third
                            parties for direct marketing purposes. If you're a California resident and would like to
                            make such a request, please call
                            us at <a rktLink href="tel:888-444-7492">888-444-7492</a>.
                        </p>
                        <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Back to Top</a>
                    </div>
                    <div class="rkt-Spacing--mb24"><mat-divider
                            class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                    <!-- "Processing of Information" section -->
                    <div rktStackItem id="processing-of-information">
                        <h1 class="rkt-Heading-36 rkt-Spacing--mb8">Processing Of Information In The United States and
                            Other Countries</h1>
                        <p class="rkt-Body-14 rkt-Spacing--mb8">
                            Rocket is headquartered in the United States, and the Rocket Family of Companies offers
                            goods and services to consumers in the United
                            States. We have operations and vendors in the United States and other countries. Therefore,
                            we and those that perform work for us may
                            transfer your personal information to, or store or access it in, jurisdictions that may not
                            provide levels of data protection that are
                            equivalent to those of your home jurisdiction.
                        </p>
                        <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Back to Top</a>
                    </div>
                    <div class="rkt-Spacing--mb24"><mat-divider
                            class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                    <!-- "Your Choices" section -->
                    <div rktStackItem id="your-choices">
                        <h1 class="rkt-Heading-36 rkt-Spacing--mb8">Your Choices</h1>
                        <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                        <mat-accordion>
                            <mat-expansion-panel class="rkt-AccordionPanel"
                                idString="right-to-correct-account-information" [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="rkt-AccordionPanel__header-title">
                                        <h3 class="rkt-Heading-28">Right to Correct Account Information</h3>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    You may update and correct certain account information, or delete your account, by
                                    logging into your account or calling us at
                                    <a rktLink href="tel:888-444-7492">888-444-7492</a>. Please note that we may retain
                                    certain information as required by law or for our
                                    legitimate business purposes. For more information, please see <a rktLink
                                        href="#/privacy-policy#additional-information">Additional Information Regarding
                                        Your State Privacy Rights</a>.
                                </p>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <div class="rkt-Spacing--mb24"></div>
                        <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                        <mat-accordion>
                            <mat-expansion-panel class="rkt-AccordionPanel" idString="location-information"
                                [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="rkt-AccordionPanel__header-title">
                                        <h3 class="rkt-Heading-28">Location Information</h3>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    When you first launch any of our mobile apps that collect precise location
                                    information,
                                    or when you visit portions of our Services that collect precise location
                                    information,
                                    you'll be asked to consent to the collection of this information. If you initially
                                    consent to our collection of such location information,
                                    you can subsequently stop the collection of this information at any time by changing
                                    the preferences on your mobile device.
                                    It is important to note that some Services may not function without precise location
                                    information. You may also stop a mobile app's
                                    collection of location information by following the standard uninstall process to
                                    remove the app from your device.
                                </p>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <div class="rkt-Spacing--mb24"></div>
                        <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                        <mat-accordion>
                            <mat-expansion-panel class="rkt-AccordionPanel" idString="do-not-track-signals"
                                [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="rkt-AccordionPanel__header-title">
                                        <h3 class="rkt-Heading-28">Do Not Track Signals</h3>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    You may be using an Internet browser that can communicate your privacy preferences
                                    to the website, including requests not to
                                    track your usage and browsing history. Our websites are configured to recognize
                                    those browser signals.
                                    If we recognize one of these signals – which may be referred to or are labeled by
                                    your browser as “do not track” or
                                    “opt-out preference” or “global preference” signals – we give you the option of
                                    submitting a request to opt-out of sale/sharing.
                                    For more information on opting out of the sale/sharing of personal information,
                                    please see Additional Information Regarding Your State Privacy Rights, below.
                                </p>
                            </mat-expansion-panel>
                        </mat-accordion>

                        <div class="rkt-Spacing--mb24"></div>
                        <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Back to Top</a>
                    </div>
                    <div class="rkt-Spacing--mb24"><mat-divider
                            class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                    <!-- "Changes To This Policy" section -->
                    <div rktStackItem id="changes-to-this-policy">
                        <h1 class="rkt-Heading-36 rkt-Spacing--mb8">Changes To This Policy</h1>
                        <p class="rkt-Body-14 rkt-Spacing--mb8">
                            We may change this <b>Rocket Family of Companies Privacy Policy</b> from time to time. If we
                            make changes, we'll notify you by revising the
                            date at the top of this policy and, in some cases, we may provide you with additional
                            notice, such as adding a statement to our
                            website or sending you a notification. We encourage you to review this policy regularly to
                            stay informed about our information
                            practices and the choices available to you.
                        </p>
                        <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Back to Top</a>
                    </div>
                    <div class="rkt-Spacing--mb24"><mat-divider
                            class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                    <div rktStackItem id="contact-information">
                        <h1 class="rkt-Heading-36 rkt-Spacing--mb8">Contact Information</h1>
                        <p class="rkt-Body-14 rkt-Spacing--mb8">
                            If you have questions regarding this <b>Rocket Family of Companies Privacy Policy</b>, our
                            information practices or other aspects of privacy
                            relating to our Services, call us at <a rktLink href="tel:888-444-7492">888-444-7492</a>.
                        </p>
                        <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Back to Top</a>
                    </div>
                    <div class="rkt-Spacing--mb24"><mat-divider
                            class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                    <!-- "Additional Information" section -->
                    <div rktStackItem id="additional-information">
                        <h1 class="rkt-Heading-36 rkt-Spacing--mb8">ADDITIONAL INFORMATION REGARDING YOUR STATE PRIVACY
                            RIGHTS</h1>
                        <p class="rkt-Body-14 rkt-Spacing--mb8">
                            Some states require that we provide additional information regarding our privacy practices
                            and your privacy rights.
                            States that have passed comprehensive privacy laws giving residents specific privacy rights
                            include: California,
                            Colorado, Connecticut, Delaware, Indiana, Iowa, Montana, Oregon, Tennessee, Texas, Utah and
                            Virginia.
                        </p>
                        <p class="rkt-Body-14 rkt-Spacing--mb8">
                            Below we provide you additional notices about our privacy practices
                            and consumer rights relating to your information. Some of the rights may depend on when the
                            law is effective and whether you are a resident of a state with specific privacy laws,
                            as well as other factors. Also, some of the companies within the Rocket Family of Companies
                            are financial institutions subject
                            to other regulatory privacy frameworks, such as the Gramm Leach Bliley Act (GLBA), and the
                            privacy disclosure and rights described in this
                            section may not apply.
                        </p>
                        <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                        <mat-accordion>
                            <mat-expansion-panel class="rkt-AccordionPanel" idString="notice-of-information-collected"
                                [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="rkt-AccordionPanel__header-title">
                                        <h3 class="rkt-Heading-28">Notice of Information Collected</h3>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    In the preceding 12 months, we have collected the following categories of personal
                                    information:
                                </p>
                                <table mat-table [dataSource]="InfoCollectedTable" class="rkt-Table rkt-Table--striped-odd rkt-Spacing--mb24">
                                    <ng-container matColumnDef="category">
                                        <th mat-header-cell *matHeaderCellDef class="rkt-Table__header">Category</th>
                                        <td class="rkt-Table__cell" mat-cell *matCellDef="let element">
                                            {{ element.category }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="detail">
                                        <th mat-header-cell *matHeaderCellDef class="rkt-Table__header">Examples</th>
                                        <td class="rkt-Table__cell" mat-cell *matCellDef="let element">
                                            {{ element.detail }}
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                                    <tr mat-row *matRowDef="let row; let even = even; columns: tableColumns"></tr>
                                </table>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    <b>For details about the precise data points we collect and the categories of
                                        sources of such collection, please see the
                                        <a rktLink href="#/privacy-policy#information-we-collect">Information We
                                            Collect</a> section.</b> We collect personal information
                                    for the business and commercial purposes described in the <a rktLink
                                        href="#/privacy-policy#how-we-use">How We Use Your Information</a>
                                    section.
                                </p>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <div class="rkt-Spacing--mb24"></div>
                        <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                        <mat-accordion>
                            <mat-expansion-panel class="rkt-AccordionPanel"
                                idString="disclosure-of-personal-information" [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="rkt-AccordionPanel__header-title">
                                        <h3 class="rkt-Heading-28">Disclosure Of Personal Information</h3>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    In the preceding 12 months, we've disclosed the following categories of personal
                                    information for business or commercial purposes
                                    to the following categories of recipients:
                                </p>
                                <table mat-table [dataSource]="InfoSentTable" class="rkt-Table rkt-Table--striped-odd rkt-Spacing--mb24">
                                    <ng-container matColumnDef="category">
                                        <th mat-header-cell *matHeaderCellDef class="rkt-Table__header">Category of Personal Information</th>
                                        <td class="rkt-Table__cell" mat-cell *matCellDef="let element">
                                            {{ element.category }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="detail">
                                        <th mat-header-cell *matHeaderCellDef class="rkt-Table__header">Categories of Recipients</th>
                                        <td class="rkt-Table__cell" mat-cell *matCellDef="let element">
                                            {{ element.detail }}
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                                    <tr mat-row *matRowDef="let row; let even = even; columns: tableColumns"></tr>
                                </table>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <div class="rkt-Spacing--mb24"></div>
                        <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                        <mat-accordion>
                            <mat-expansion-panel class="rkt-AccordionPanel"
                                idString="right-to-limit-processing-of-sensitive-personal-information"
                                [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="rkt-AccordionPanel__header-title">
                                        <h3 class="rkt-Heading-28">Right to Limit Processing of Sensitive Personal
                                            Information</h3>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    In California, you have the right to limit our processing of your Sensitive Personal
                                    Information to only uses which are necessary to
                                    perform the services or provide the goods reasonably expected by an average consumer
                                    who requests such goods or services. When we collect
                                    your Sensitive Personal Information, we collect and use that information to perform
                                    the services or provide the goods for which that
                                    information is necessary and as reasonably expected by you.
                                </p>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    Under other state privacy laws, we are only permitted to collect certain pieces of
                                    Sensitive Personal Information after we have obtained your consent to do so.
                                    Where required, we get your agreement to collect and use Sensitive Personal
                                    Information.
                                </p>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <div class="rkt-Spacing--mb24"></div>
                        <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                        <mat-accordion>
                            <mat-expansion-panel class="rkt-AccordionPanel" idString="sale-of-personal-information"
                                [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="rkt-AccordionPanel__header-title">
                                        <h3 class="rkt-Heading-28">Sale Of Personal Information</h3>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    State privacy laws require certain disclosures for companies that "sell" personal
                                    information
                                    pursuant to the respective state's privacy law. Each state defines the "sale" of
                                    data differently. In California, the "sale" of data
                                    means certain scenarios in which Rocket has shared personal information with third
                                    parties or Affiliates, in exchange for valuable
                                    consideration. Other states define the "sale" of data as Rocket exchanging personal
                                    information for
                                    monetary consideration with a non-affiliated third party. Under this definition, we
                                    do not "sell" your personal information.
                                </p>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    Under California's definition, we "sell" personal information to enable our
                                    Affiliates and certain third parties to use your
                                    information in accordance with their privacy policies. We "sell" the following
                                    categories of personal information to the following
                                    categories of third parties:
                                </p>
                                <table mat-table [dataSource]="SaleOfPersonalInfoTable" class="rkt-Table rkt-Table--striped-odd rkt-Spacing--mb24">
                                    <ng-container matColumnDef="category">
                                        <th mat-header-cell *matHeaderCellDef class="rkt-Table__header">Category of Personal Information</th>
                                        <td class="rkt-Table__cell" mat-cell *matCellDef="let element">
                                            {{ element.category }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="detail">
                                        <th mat-header-cell *matHeaderCellDef class="rkt-Table__header">Categories of Third Parties</th>
                                        <td class="rkt-Table__cell" mat-cell *matCellDef="let element">
                                            {{ element.detail }}
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                                    <tr mat-row *matRowDef="let row; let even = even; columns: tableColumns"></tr>
                                </table>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <div class="rkt-Spacing--mb24"></div>
                        <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                        <mat-accordion>
                            <mat-expansion-panel class="rkt-AccordionPanel" idString="right-to-opt-out-of-selling"
                                [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="rkt-AccordionPanel__header-title">
                                        <h3 class="rkt-Heading-28">Right to Opt-Out of Selling</h3>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    You have the right to opt out of the "sales" described above at any time by visiting
                                    our
                                    <a rktLink href="#/do-not-sell-my-personal-information" target="blank">Do Not Sell
                                        My Personal Information</a> webpage.
                                    We do not knowingly sell or share personal information about consumers under the age
                                    of 16.
                                </p>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <div class="rkt-Spacing--mb24"></div>
                        <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                        <mat-accordion>
                            <mat-expansion-panel class="rkt-AccordionPanel"
                                idString="right-to-opt-out-of-personal-information" [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="rkt-AccordionPanel__header-title">
                                        <h3 class="rkt-Heading-28">Right to Opt-Out of Personal Information Being Shared
                                            or Used In Targeted Advertising</h3>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    California requires that we notify you if we “Share” your personal information with
                                    third parties. In California, “Sharing” means
                                    we make available or otherwise disclose your information to third parties for
                                    cross-context behavioral advertising. Further,
                                    “cross-context behavioral advertising” means targeting of advertising to you based
                                    on your personal information obtained from
                                    your activity across businesses, websites, apps, and services other than our
                                    Services.
                                </p>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    Similarly, privacy laws in other states require we notify you if we use your
                                    information for Targeted
                                    Advertising. Targeted Advertising means displaying advertisements to you where the
                                    advertisement is selected based on personal information
                                    obtained from your activities over time and across nonaffiliated websites or online
                                    applications to predict your preferences or interests.
                                </p>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    Accordingly, we "Share" your personal information with third parties for purposes of
                                    cross-contextual advertising,
                                    and we use your information for Targeted Advertising. Specifically, we share your
                                    identifiers and internet or other network activity with our advertising partners.
                                </p>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    <b>You have the right to opt out of "sharing" or Targeted Advertising by accessing
                                        our
                                        <a rktLink href="#/do-not-sell-my-personal-information" target="blank">Do Not
                                            Sell My Personal Information</a> webpage.
                                        We also honor browser “Do Not Track” signals, as noted above.</b>
                                </p>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <div class="rkt-Spacing--mb24"></div>
                        <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                        <mat-accordion>
                            <mat-expansion-panel class="rkt-AccordionPanel" idString="retention-of-personal-information"
                                [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="rkt-AccordionPanel__header-title">
                                        <h3 class="rkt-Heading-28">Retention of Personal Information</h3>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    We will not retain your personal information for longer than is reasonably necessary
                                    to carry out the purposes we disclose in this policy.
                                </p>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <div class="rkt-Spacing--mb24"></div>
                        <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider "></mat-divider></div>
                        <mat-accordion>
                            <mat-expansion-panel class="rkt-AccordionPanel" idString="state-consumer-data-rights"
                                [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="rkt-AccordionPanel__header-title">
                                        <h3 class="rkt-Heading-28">State Consumer Data Rights</h3>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    Subject to certain limitations, you have the right to (1) request to know more about
                                    the categories and specific pieces of
                                    personal information we collect, use, disclose and sell, and to access your
                                    information, (2) request deletion of your personal information, (3) request
                                    correction
                                    of your personal information and (4) not be discriminated against for exercising
                                    these rights.
                                </p>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    <b>You may make these requests by
                                        <a rktLink
                                            href="https://www.rocketmortgage.com/privacy-rights/data-request?qls=QRD_12345678.0123456789"
                                            target="blank">
                                            starting a request online</a> or calling <a rktLink
                                            href="tel:888-444-7492">888-444-7492</a>.
                                    </b>
                                    We'll verify your request by asking you to provide information related to your
                                    recent interactions with us.
                                </p>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    If we receive your request from an authorized agent, we may ask for evidence that
                                    you've provided such agent with a power of attorney,
                                    or that the agent otherwise has valid written authority to submit requests to
                                    exercise rights on your behalf.
                                    <b>If you're an authorized agent seeking to make a request, please contact us as set
                                        out above.</b>
                                </p>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <div class="rkt-Spacing--mb24"></div>
                        <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                        <mat-accordion>
                            <mat-expansion-panel class="rkt-AccordionPanel" idString="ca-financial-incentive-disclosure"
                                [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="rkt-AccordionPanel__header-title">
                                        <h3 class="rkt-Heading-28">California Financial Incentive Disclosure</h3>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    California’s privacy law requires that we notify you if we provide you a program,
                                    benefit, or other offering, related to the collection,
                                    deletion, or sale of personal information, which it defines as a “financial
                                    incentive.” We may offer you certain financial incentives that
                                    may result in different prices, rates, or quality levels (for example, contests and
                                    referral programs). Through these offerings, consumers
                                    provide us with some personal information (e.g., names, emails, phone numbers) when
                                    they opt-in to our programs. There is no obligation to
                                    opt-in and consumers may opt-out at any time. The details of each program are
                                    contained in the program offering. We offer these programs,
                                    among other things, to enhance our relationship with you so you can enjoy more of
                                    our products/services at a lower price. We invest heavily
                                    in our marketing and brands, in part, so we can provide programs to our customers.
                                    Consumer data is valuable to our business only when it is
                                    combined with a sufficient number of other consumer data and after it is enhanced by
                                    our efforts herein described. The value to our business
                                    of any individual consumer's personal information is dependent on a number of
                                    factors, including, for example, whether and to what extent you
                                    take advantage of any offerings, whether and to what extent you opt out of any
                                    offerings, and whether we are able to enhance the data through
                                    our efforts herein described. Our ability to create any value from the programs is
                                    heavily based on our ability to leverage said intellectual
                                    properties. We do not calculate the value of consumer data in our accounting
                                    statements.
                                </p>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <div class="rkt-Spacing--mb24"></div>
                        <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                        <mat-accordion>
                            <mat-expansion-panel class="rkt-AccordionPanel" idString="ca-verifiable-client-requests"
                                [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="rkt-AccordionPanel__header-title">
                                        <h3 class="rkt-Heading-28">California Consumer Data Requests</h3>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    In 2023, we received 421 consumer data requests from California consumers. This
                                    includes requests from people to know, access,
                                    or delete their data. We also received 48,406 requests from people who opted out of
                                    data sharing.
                                </p>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                    Here’s a breakdown of those consumer requests:
                                    <br>
                                    <b>119</b> Right To Know/Access requests
                                    <br>
                                    <b>302</b> Right To Delete requests
                                </p>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">It's taken us a median of 2 days to process and
                                    respond to each request.</p>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <div class="rkt-Spacing--mb24"></div>
                        <a rktLink class="top-link margin--bottom" href="#/privacy-policy#top">Back to Top</a>
                    </div>
                    <div class="rkt-Spacing--mb24"><mat-divider
                            class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                    <!-- "Federal Privacy Policy section -->
                    <div rktStackItem id="federal-privacy-notice">
                        <h1 class="rkt-Heading-36 rkt-Spacing--mb8">Federal Privacy Notice</h1>
                        <p class="rkt-Body-14 rkt-Spacing--mb8">
                            Some companies within the Rocket Family of Companies are required to provide an additional
                            Privacy Notice under the Gramm-Leach-Bliley Act.
                            For convenience, these notices are provided below.
                        </p>
                        <p class="rkt-Body-14 rkt-Spacing--mb8">
                            <a rktLink href="https://static.rocketloans.com/disclosures/our-privacy-notice.pdf"
                                target="blank">Rocket Loans</a>
                            <br>
                            <a rktLink href="https://www.rocketmoney.com/privacy-notice" target="blank">Rocket Money</a>
                            <br>
                            <a rktLink href="/assets/pdf/ServicingPrivacyPolicyV2.pdf" target="blank">Rocket
                                Mortgage</a>
                            <br>
                            <a rktLink href="https://www.rocketcard.com/privacy-notice" target="blank">Rocket Card</a>
                        </p>
                        <a rktLink class="top-link margin--bottom" href="#/privacy-policy#top">Back to Top</a>
                    </div>
                    <div class="rkt-Spacing--mb24"></div>
                </rkt-stack>
            </div>
            <!-- 2/5 stack w/table of contents -->
            <div rktStackItem class="rkt-Stack__item--two-fifths@6 rightColumn">
                <!-- print button -->
                <div class="print-button--container print-button--wide">
                    <button class="rkt-Button rkt-Button--secondary" mat-stroked-button color="accent" id="print-button"
                        (click)="printPrivacyPolicy()">Print privacy policy</button>
                </div>
                <!-- table of contents -->
                <mat-card idString="default" mat-card class="rkt-Card table-of-contents--wide rkt-Card--white">
                    <h3 class="rkt-Heading-40 rkt-Spacing--mb16">
                        Table of Contents
                    </h3>
                    <rkt-stack spacing="8">
                        <div rktStackItem variant="bodyTwo" class="table-of-contents--contents">
                            <a rktLink href="#/privacy-policy#who-we-are">Who We Are</a>
                            <a rktLink href="#/privacy-policy#information-we-collect">Information We Collect</a>
                            <a rktLink href="#/privacy-policy#how-we-use">How We Use Your Information</a>
                            <a rktLink href="#/privacy-policy#how-we-share">How We Share Your Information</a>
                            <a rktLink href="#/privacy-policy#advertising-and-analytics">Advertising and Analytics</a>
                            <a rktLink href="#/privacy-policy#childrens-privacy">Children's Privacy</a>
                            <a rktLink href="#/privacy-policy#california-shine-the-light">California Shine The Light</a>
                            <a rktLink href="#/privacy-policy#processing-of-information">Processing Of Information In
                                The United States and Other Countries</a>
                            <a rktLink href="#/privacy-policy#your-choices">Your Choices</a>
                            <a rktLink href="#/privacy-policy#changes-to-this-policy">Changes To This Policy</a>
                            <a rktLink href="#/privacy-policy#contact-information">Contact Information</a>
                            <a rktLink href="#/privacy-policy#additional-information">Additional Information Regarding
                                Your State Privacy Rights</a>
                            <a rktLink href="#/privacy-policy#federal-privacy-notice">Federal Privacy Notice</a>
                        </div>
                    </rkt-stack>
                </mat-card>
            </div>
        </rkt-stack>
        <!-- nearly identical table of contents for smaller viewports: accordion at bottom of page -->
        <mat-accordion>
            <mat-expansion-panel class="rkt-AccordionPanel table-of-contents--narrow" *ngIf="showTableOfContents">
                <mat-expansion-panel-header>
                    <mat-panel-title class="rkt-AccordionPanel__header-title">
                        <h3 class="rkt-Heading-40">Table of Contents</h3>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div rktStackItem variant="bodyTwo" class="table-of-contents--contents">
                    <a rktLink href="#/privacy-policy#who-we-are">Who We Are</a>
                    <a rktLink href="#/privacy-policy#information-we-collect">Information We Collect</a>
                    <a rktLink href="#/privacy-policy#how-we-use">How We Use Your Information</a>
                    <a rktLink href="#/privacy-policy#how-we-share">How We Share Your Information</a>
                    <a rktLink href="#/privacy-policy#advertising-and-analytics">Advertising and Analytics</a>
                    <a rktLink href="#/privacy-policy#childrens-privacy">Children's Privacy</a>
                    <a rktLink href="#/privacy-policy#california-shine-the-light">California Shine The Light</a>
                    <a rktLink href="#/privacy-policy#processing-of-information">Processing Of Information In The United
                        States and Other Countries</a>
                    <a rktLink href="#/privacy-policy#your-choices">Your Choices</a>
                    <a rktLink href="#/privacy-policy#changes-to-this-policy">Changes To This Policy</a>
                    <a rktLink href="#/privacy-policy#contact-information">Contact Information</a>
                    <a rktLink href="#/privacy-policy#additional-information">Additional Information Regarding Your
                        State Privacy Rights</a>
                    <a rktLink href="#/privacy-policy#federal-privacy-notice">Federal Privacy Notice</a>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>