import { validateIntegrations } from './common';
/**
 * This function validates `settings.integrations` object that consists of a list of pluggable integration factories.
 *
 * @param {any} settings config object provided by the user to initialize the sdk
 *
 * @returns {Array} array of valid integration factories. The array might be empty if `settings` object does not have valid integrations.
 */
export function validatePluggableIntegrations(settings) {
  return validateIntegrations(settings, function (integration) {
    return typeof integration === 'function';
  }, 'Integration items must be functions that initialize the integrations');
}