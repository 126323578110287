import { Component, OnInit, ViewChild } from '@angular/core';
import { AnalyticsService } from 'app/services/generic/analytics.service';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { RktLinkModule, RktStackModule } from '@rocketcentral/rocket-design-system-angular';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDivider } from '@angular/material/divider';
import { MatTableModule } from '@angular/material/table';

export interface TableData {
  category: string;
  detail: string;
}

const InfoCollectedTable: TableData[] = [
  {category: "Identificadores", detail: "Nombre, alias, dirección postal, identificador en línea (incluyendo dirección IP), dirección de correo electrónico, número de Seguro Social, número de licencia de conducir u otros identificadores similares."},
  {category: "Registros de clientes", detail: "Nombre del empleador, historial de empleo, número de cuenta bancaria, número de tarjeta de crédito, número de tarjeta de débito y otra información financiera (incluyendo informes de crédito, salario y patrimonio neto)."},
  {category: "Características de las clasificaciones protegidas bajo la ley federal o estatal", detail: "Edad, sexo, estado militar y estado civil."},
  {category: "Información comercial", detail: "Registros de propiedades, productos o servicios comprados."},
  {category: "Actividad en Internet u otra red similar", detail: "Historial de navegación, historial de búsqueda, información de la interacción de un consumidor con un sitio web, aplicación o anuncio."},
  {category: "Información de geolocalización", detail: "Lugar o movimientos físicos."},
  {category: "Información sensorial", detail: "Audio (como grabaciones de llamadas) o el contenido de los videos que elija cargar."},
  {category: "Información profesional o del empleo", detail: "Empleador actual o lestado de empleo."},
  {category: "Inferencias extraídas de otra información personal", detail: "Preferencias, características y ubicación aproximada."},
  {category: "Sensitive Personal Information", detail:"Número del Seguro Social, licencia de conducir, tarjeta de identificación estatal o número de pasaporte; inicio de sesión de cuenta, número de cuenta financiera, de tarjeta de débito o de tarjeta de crédito en combinación con cualquier código de acceso o de seguridad exigido, contraseña o credenciales que permitan el acceso a una cuenta; geolocalización precisa; origen racial o étnico, creencias religiosas o filosóficas, o afiliación sindical; contenido de un correo del consumidor, correo electrónico y mensajes de texto, a menos que la empresa sea el destinatario previsto de la comunicación. "}
];

const InfoSentTable: TableData[] = [
  {category: "Identificadores", detail: "Socios (como socios hipotecarios o concesionarios de autos, dependiendo de los Servicios), redes de publicidad y proveedores de servicios (como proveedores de servicios de Internet, de análisis de datos, de servicios de correo electrónico, de almacenamiento de datos, de atención al cliente, de administración de cuentas, y sistemas operativos y plataformas)."},
  {category: "Registros de clientes", detail: "Socios (como socios hipotecarios o concesionarios de autos, dependiendo de los Servicios), redes de publicidad y proveedores de servicios (como proveedores de servicios de Internet, de análisis de datos, de servicios de correo electrónico, de almacenamiento de datos, de atención al cliente, de administración de cuentas, y sistemas operativos y plataformas)."},
  {category: "Características de clasificaciones protegidas", detail: "Proveedores de servicios, afiliados, socios hipotecarios y empresas asociadas."},
  {category: "Información comercial", detail: "Socios (como socios hipotecarios o concesionarios de autos, dependiendo de los Servicios), redes de publicidad y proveedores de servicios (como proveedores de servicios de Internet, de análisis de datos, de servicios de correo electrónico, de almacenamiento de datos, de atención al cliente, de administración de cuentas, y sistemas operativos y plataformas)."},
  {category: "Actividad en Internet u otra red", detail: "Redes publicitarias y proveedores de servicios (como proveedores de servicios de Internet, de análisis de datos, de almacenamiento de datos, y sistemas operativos y plataformas)."},
  {category: "Información de geolocalización", detail: "Socios y proveedores de servicios."},
  {category: "Información sensorial", detail: "Socios."},
  {category: "Información profesional o del empleo", detail: "Socios y proveedores de servicios."},
  {category: "Inferencias extraídas de otra información personal", detail: "Socios, redes de publicidad y proveedores de servicios (como proveedores de servicios de Internet, de análisis de datos, de servicios de correo electrónico, de almacenamiento de datos, de atención al cliente, de administración de cuentas, y sistemas operativos y plataformas)."},
  {category: "Información personal sensible", detail:"Socios (como socios hipotecarios o concesionarios de autos, dependiendo de los Servicios), redes de publicidad y proveedores de servicios (como proveedores de servicios de Internet, de análisis de datos, de servicios de correo electrónico, de almacenamiento de datos, de atención al cliente, de administración de cuentas, y sistemas operativos y plataformas), afiliados y empresas asociadas."}
];

const SaleOfPersonalInfoTable: TableData[] = [
  {category: "Identificadores", detail: "Terceros afiliados y no afiliados."},
  {category: "Categorías de información personal descrita en la subdivisión (e) de la sección 1798.80", detail: "Terceros afiliados y no afiliados."},
  {category: "Información comercial", detail: "Terceros afiliados y no afiliados."},
  {category: "Actividad en Internet u otra red", detail: "Terceros afiliados y no afiliados."},
  {category: "Inferencias extraídas de otra información personal", detail: "Terceros afiliados y no afiliados."},
]

@Component({
  standalone: true,
  imports: [
    MatCardModule, 
    MatButtonModule, 
    RktLinkModule, 
    RktStackModule,
    MatDivider,
    MatExpansionModule,
    MatTableModule, 
    MatIconModule, 
    CommonModule],
  selector: 'app-privacy-policy-spanish',

  templateUrl: './privacy-policy-spanish.component.html',
  styleUrls: ['./privacy-policy-spanish.component.scss'],
  providers: [AnalyticsService, MatAccordion]
})
export class PrivacyPolicySpanishComponent implements OnInit {

  @ViewChild(MatAccordion) accordion!: MatAccordion;
  constructor(private _analytics: AnalyticsService) { }

  tableColumns: string[] = ['category', 'detail'];
  InfoCollectedTable = InfoCollectedTable;
  InfoSentTable = InfoSentTable;
  SaleOfPersonalInfoTable = SaleOfPersonalInfoTable;
  showTableOfContents = true;

  ngOnInit() {
    this._analytics.pageViewEvent("Privacy Policy-(Spanish)");
  }

  ngAfterViewInit() {
    
  }

  printPrivacyPolicy() {
    this.showTableOfContents = false;
    setTimeout(() => {
      this.print();
      this.showTableOfContents = true;
    }, 200);
  }

  print() {
    window.print();
  }

  scrollToTop(event: any) {
    event.preventDefault();
    scrollTo(0,0);
  }
}
