<div class="mfa-column">
    <p class="rkt-TextAlign--center">
        <img src="/assets/img/logo/MagicO.svg" id="horseShoeLogo" alt="Rocket Logo">
    </p>
    <h1 class="rkt-TextAlign--center rkt-Heading-36 rkt-Heading-36--responsive">Oops! Something went</h1>
    <h1 class="rkt-TextAlign--center rkt-Heading-36 rkt-Heading-36--responsive">wrong on our end</h1>

    <div class="rkt-Body-16 rkt-TextAlign--center rkt-Spacing--mb4 rkt-Spacing--mt32">
        It's not you. It's us.
    </div>

    <div *ngIf="src?.toLowerCase()===pageSource.MFA" id="mfa-display" class="rkt-TextAlign--center rkt-Decorative-32 rkt-Decorative-32--responsive rkt-Spacing--mb4">
        Don't worry. Your information's secure.
    </div>

    <div class=" rkt-Body-16 rkt-TextAlign--center rkt-Spacing--mb4">
        Please try again later.
    </div>
</div>
