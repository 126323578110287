import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { PageSource } from 'app/models/page-source';
import { SplitService } from 'app/modules/feature-flags/services/split.service';
import { AnalyticsService } from 'app/services/generic/analytics.service';
import { LogoScriptService } from 'app/services/generic/logo-script.service';
import { SalesforceChatService } from 'app/services/salesforce-chat.service';
import { ScriptService } from 'app/services/script.service';
import { LogoBlockComponent } from 'app/components/logo-block/logo-block.component';
import { UtilModule } from 'app/util/util.module';
import { CommonModule } from '@angular/common';
import { RktLinkModule, RktIconModule } from '@rocketcentral/rocket-design-system-angular';
import { MatIconModule } from '@angular/material/icon'

const SCRIPT_PATH = `https://service.force.com/embeddedservice/5.0/esw.min.js`;

@Component({
    standalone: true,
    imports: [UtilModule, LogoBlockComponent, CommonModule, RktLinkModule, MatIconModule, RktIconModule],
    selector: 'app-talk-to-us',
    templateUrl: './talk-to-us.component.html',
    styleUrls: ['./talk-to-us.component.scss'],
    providers: [
      SalesforceChatService, 
      LogoScriptService, 
      ScriptService, 
      SplitService,
      AnalyticsService,
    ],
})
export class TalkToUsComponent implements OnInit {

  public src?: PageSource
  public pageSource = PageSource;
  ttuFlagIsActive?: boolean;

  constructor(
    private logoScriptService: LogoScriptService,
    private readonly _activatedRoute: ActivatedRoute,
    private _analytics: AnalyticsService,
    private split: SplitService
  ) {}

  ngOnInit() {
    this._analytics.pageViewEvent("Talk to us");
    this.logoScriptService.logoScript(window, document, "body {opacity: 0 !important}");

    this.getTreatments();
    this._activatedRoute.queryParams.subscribe(params => this.src = params['src']);
  }

  async getTreatments() {
    this.ttuFlagIsActive = await this.split.getTreatmentFor("ttu-rocketmoney-content");
  }

  goBack() {
    window.history.back();
  }
}
