class CookieOptions {
	path?: string;
	domain?: string;
	maxAge?: number;
	expires?: string;
	secure?: boolean;
	sameSite?: string;
}

// all supported browsers as of September 2022
export const browserList = [ 
	{
		name: 'Chrome',
		minVersion: 94
	},
	{
		name: 'Chromium',
		minVersion: 94
	},
	{
		name: 'Firefox',
		minVersion: 79
	},
	{
		name: 'Edg', // this is how Edge displays its user agent
		minVersion: 94
	},
	{
		name: 'Safari',
		minVersion: 538 // the build # corresponding to Chrome v94
	}
];

export function readCookie(name: string): string | null {
	let cookies = document.cookie.split('; ');
	let cookie = cookies.find( elem => elem.includes(name + '='));
	return cookie ? cookie.split('=')[1] : null
}

export function writeCookie(key: string, value: string, options?: CookieOptions): void {
	let cookie = `${key}=${encodeURIComponent(value)}`;

	if (options?.path) { cookie += `;path=${options.path}` }
	if (options?.domain) { cookie += `;domain=${options.domain}` }
	if (options?.maxAge) { cookie += `;max-age=${options.maxAge}` }
	if (options?.expires) { cookie += `;expires=${options.expires}`}

	let browserHasSameSiteCookie = isMinimumVersionForSameSiteCookie()

	if (browserHasSameSiteCookie) {
		if (options?.secure) { cookie += `;secure=${options.secure}`}
		if (options?.sameSite) { cookie += `;sameSite=${options.sameSite}`}
	}

	document.cookie = cookie;
}

export function deleteCookie(key: string, options?: CookieOptions ): void {
	let deleteOptions = Object.assign({}, options, { maxAge: -1 });
	writeCookie(key, '', deleteOptions);
}

// returns true if user's browser version is greater (i.e. released later) 
// than the version where SameSite default behavior has changed
export function isMinimumVersionForSameSiteCookie(): Boolean {
	let userAgent = window.navigator.userAgent; // Chrome, Firefox, Edge, or Safari are supported
	for (let browser of browserList) {
		if (userAgent.includes(browser.name)) {
			let majorVersionString = userAgent.split(browser.name + '/')[1].split('.')[0];
			let majorVersion = Number.parseInt(majorVersionString)
			if (majorVersion >= browser.minVersion) {
				return true;
			}
		}
	}
	return false;
}
