<single-column [talk]="false">
  <div class="text-center" *ngIf="isValid && !isComplete">
      <h1 class="rkt-Heading-48 rkt-Heading-48--responsive rkt-Spacing--mb24">We’re verifying your email address right now.</h1>
      <img src="/assets/img/Email-Unverified.svg" class="center-block" alt="Unverified Email">
  </div>
  <div class="text-center" *ngIf="isValid && !emailVerified && timeValid && isComplete">
      <ng-container [ngSwitch]="verifyType">
          <ng-container *ngSwitchCase="'createAccount'">
              <h1 class="rkt-Heading-48 rkt-Heading-48--responsive rkt-Spacing--mb24">Success! <br>Your account is all set up.</h1>
              <img src="/assets/img/Email-Success.svg" class="center-block" alt="Verified Email">
              <button class="rkt-Button rkt-Button--large" mat-flat-button color="primary" data-analytics-click="button:Verified Email Continue" (click)="goToRedirect()" id="verify-continue">Continue</button>
          </ng-container>
          <ng-container *ngSwitchCase="'usernameUpdate'">
              <h1 class="rkt-Heading-48 rkt-Heading-48--responsive rkt-Spacing--mb24">You've successfully changed your username.</h1>
              <p class="rkt-Body-16 rkt-Spacing--mb4">Now, let's get you signed in.</p>
              <img src="/assets/img/Email-Success.svg" class="center-block" alt="Username Changed">
              <button class="rkt-Body-16 rkt-Button rkt-Button--large" mat-flat-button color="primary" (click)="goToRedirect()" id="verify-continue">Go to sign-in</button>
          </ng-container>
      </ng-container>
  </div>
<div class="text-center" *ngIf="!isValid && expired">
  <h1 class="rkt-Heading-48 rkt-Heading-48--responsive rkt-Spacing--mb24">Invalid or expired verification link.</h1>
  <p class="rkt-Body-16 rkt-Spacing--mb4">Please request a new verification email.</p>
  <img src="/assets/img/Email-Error.svg" class="center-block" alt="Email Error">
</div>
<div class="text-center" *ngIf="!isValid && genericError">
  <h1 class="rkt-Heading-48 rkt-Heading-48--responsive rkt-Spacing--mb24">Generic Error</h1>
  <p class="rkt-Body-16 rkt-Spacing--mb4">Uh oh! Something isn't working. Please try again or please <a routerLink="/talk-to-us">contact us</a>.</p>
  <img src="/assets/img/Email-Error.svg" class="center-block" alt="Email Error">
</div>
</single-column>