<div class="container rkt-TextAlign--center">    
    <img src="/assets/img/background/404-rocket.svg">
    <h1 class="rkt-Heading-48 rkt-Heading-48--responsive">Someone moved the rocket again...</h1>
    <p class="rkt-Body-16 rkt-Spacing--mb16">The page you're looking for is out here <br/> somewhere, but we can't find it.</p> 
    <div id="buttons">
        <a
        class="rkt-Button rkt-Button--large sign-in-btn"
        mat-flat-button
        routerLink="/sign-in"
        color="primary"
        target="_blank"
        >
            Sign in
        </a>
        <a
        class="rkt-Button rkt-Button--large create-account-btn"
        mat-flat-button
        routerLink="/create-account"
        color="primary"
        target="_blank"
        >
            Create account
        </a>
    </div>
</div>
