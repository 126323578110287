import { Injectable } from '@angular/core';

declare let _satellite: any;
@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    public adobeDataLayer: any;

    constructor() {
        this.adobeDataLayer = (window as any)['adobeDataLayer'] = (window as any)['adobeDataLayer'] || [];
    }

    public pageViewEvent(page: string) {
        this.adobeDataLayer.push({
            event: "PAGEVIEW",
            page: {
                pagename: page
            }
        });
    }

    // standard satellite tracking passing in name of event
    public track(name: string) {
        if (typeof _satellite === 'object') {
            _satellite.track(name);
        }
    }

    // satellite tracking passing in name of event with available raid
    public trackWithID(name: string, raid: String) {
        if (typeof _satellite === 'object') {
            _satellite.track(name, { raid: raid });
        }
    }

    // decode token when request is made from ping fed to send ra id to bi
    public decodeToken(token: string): String {
        const payload = JSON.parse(atob(token.split('.')[1]));
        this.storeId(payload.rocketAccountId);
        return payload.rocketAccountId;
    };

    // send token in standard format ra id to bi
    public storeId(id: string) {
        document.cookie = 'raid=' + id;
    };
}
