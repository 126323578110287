import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageSource } from 'app/models/page-source';

@Component({
  selector: 'error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  public src?: PageSource
  public pageSource = PageSource;

  constructor(   
     private readonly _activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this._activatedRoute.queryParams.subscribe(params => this.src = params['src']);
  }
  

}
