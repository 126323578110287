import { Injectable } from '@angular/core';
import { RedirectService } from 'app/services/generic/redirect.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { MetricsService } from 'app/services/metrics.service';
import { environment } from 'environments/environment';
import deepmerge from 'deepmerge';

@Injectable()
export class AccountService {
    private url = `${environment.apiConsumerBaseV2}/account/`;

    private headers = new HttpHeaders({
      'Content-Type': environment.headers.version,
      'Accept': environment.headers.version
    });

    private stubHeaders = 	new HttpHeaders({
      'Content-Type': environment.headers.version,
      'Accept': environment.headers.standard
    });

    constructor(
      private _redirectService: RedirectService,
      private metrics: MetricsService,
      private http: HttpClient,
    ) {
    }

   /**
   * Create params object based on argument values.
   * @param consentValue The value of the "consent" param
   * @returns key-value pairs of params
   */
    private getParams(consentValue: boolean): { [key: string]: string } {
      const params: any = {};

      if (consentValue !== null) {
        params['consent'] = `${consentValue}`;
      }

      return params;
    }

    set redirect(url) {
      this._redirectService.redirect = url;
    }

    get redirect() {
      return this._redirectService.redirect;
    }

    postCreate(newUser: any, emailVerification: any, welcomeEmail = true, rocketConsent: any) {
      sessionStorage.setItem('verifyFor', newUser.username);
      newUser.emailVerify = emailVerification;
      let payload: any = deepmerge(newUser, this.metrics.metrics);

      if(this.redirect && this.redirect.indexOf('/#/success-landing') <= -1)
        payload['redirect'] = this.redirect;

      var params = new HttpParams();

      if (!welcomeEmail) {
        params = params.set('sendWelcomeEmail', welcomeEmail);
      }

      params = params.set('login', true);

      if("metricIdentifiers" in payload) payload["metricIdentifiers"] = JSON.stringify(payload["metricIdentifiers"]);

      if(!rocketConsent){
        const params = this.getParams(newUser.hasConsent);
        return this.http.post(this.url, JSON.stringify(payload), { headers: this.headers, params: params} );
      }      

      return this.http.post(this.url + '?' + params.toString(), JSON.stringify(payload), { headers: this.headers} );
    }

    postCreateStub(newUser: any) {
      let payload = Object.assign({}, newUser, this.metrics.metrics);
      return this.http.post(environment.apiConsumerBaseV2 + '/account/stub/', JSON.stringify(payload),
        { headers: this.stubHeaders});
    }
}
