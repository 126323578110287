import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AlertComponent } from 'app/util/alert/alert.component';
import { MaskComponent } from 'app/util/mask/mask.component';
import { ModalComponent } from 'app/util/modal/modal.component';
import { ButtonComponent } from 'app/util/button/button.component';
import {
  RcktInputComponent,
  RcktInputErrorComponent,
  RcktHelpLinkComponent,
} from 'app/util/rckt-input/rckt-input.component';
import { SingleColumnComponent } from 'app/util/single-column/single-column.component';
import { TosComponent } from 'app/util/tos/tos.component';
import { RktLinkModule } from '@rocketcentral/rocket-design-system-angular';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    RktLinkModule,
  ],
  declarations: [
    AlertComponent,
    ButtonComponent,
    MaskComponent,
    ModalComponent,
    RcktInputComponent,
    RcktInputErrorComponent,
    RcktHelpLinkComponent,
    SingleColumnComponent,
    TosComponent,
  ],
  exports: [
    AlertComponent,
    ButtonComponent,
    MaskComponent,
    ModalComponent,
    RcktInputComponent,
    RcktInputErrorComponent,
    RcktHelpLinkComponent,
    SingleColumnComponent,
    TosComponent,
  ]
})
export class UtilModule { }
