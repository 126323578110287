import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
    standalone: true,
    selector: 'app-create-account-form',
    templateUrl: './create-account-form.component.html',
    styleUrls: ['./create-account-form.component.scss'],
    providers: [AuthService],
})
export class CreateAccountFormComponent implements OnInit {

    constructor(
        public auth: AuthService
    ) {}
    
    async ngOnInit() {
        this.auth.loginWithRedirect({
            authorizationParams: {
                screen_hint: 'signup'
            }
        });
    }
}
