import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { MetricsService } from 'app/services/metrics.service';

@Injectable()
export class MetricsResolver implements Resolve<any> {

  constructor(private metricsService: MetricsService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    let metricsQuery = route.queryParams['metricIdentifiers'];
    if (metricsQuery) {
      let decodedMetrics = decodeURIComponent(metricsQuery);
      var jsonObj = JSON.parse(decodedMetrics);
      this.metricsService.addMetricIdentifier(jsonObj);
    }
  }
}
