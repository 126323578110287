import { ValidatorFn, AbstractControl, FormGroup } from '@angular/forms';
import { regex } from 'app/util/regex';

export function oneName(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any}| null => {
    const twoNames = /(?:^|\W)(and|or)(?:$|\W)/.test(control.value);
    return twoNames ? {'onename': true} : null;
  }
}

export function containsDigit(): ValidatorFn {
	return (control: AbstractControl): {[key: string]: any} | null => {
		if (!control.value) return null;
		const digits = new RegExp(/\d+/);
		const hasDigits = digits.test(control.value);
		return hasDigits ? null : {'containsdigit': true};
	}
}

export function realDate(): ValidatorFn {
	return (control: AbstractControl): {[key: string]: any} | null => {
		const month = parseInt(control.value.substring(0,2));
		const day = parseInt(control.value.substring(3,5));
		const year = parseInt(control.value.substring(6));

		// check if month is in range
		if (month > 12 || month < 1) return { 'realdate': {'monthOutOfRange': month} };

		// check if day is in range for the month
		switch (month) {
			case 1:
			case 3:
			case 5:
			case 7:
			case 8:
			case 10:
			case 12:
				if (day > 31) return { 'realdate': {'dayOutOfRange': day} }
				break;
			case 4:
			case 6:
			case 9:
			case 11:
				if (day > 30) return { 'realdate': {'dayOutOfRange': day} }
				break;
			case 2:
				if (day > 29) return { 'realdate': {'dayOutOfRange': day} }
				break;
		}

		// check if year is in range
		if (year > new Date().getFullYear()) return { 'realdate': { 'yearOutOfRange': year } };

		return null;
	}
}

export function sessionChecker(error: any) {
  if (error.status === 401) {
		sessionStorage.setItem('invalid-session', ' ');
		window.location.reload();
  }
}

export function matchOtherValidator(secondaryControlName: string, type: string) {

  let primaryControl: AbstractControl;
  let secondaryControl: AbstractControl;

  return function matchOtherValidate(control: AbstractControl) {

    if (!control.parent) {
      return null;
    }

    // Initializing the validator.
    if (!primaryControl) {
      primaryControl = control;
      secondaryControl = control.parent.get(secondaryControlName) as AbstractControl;
      if (!secondaryControl) {
        throw new Error('matchOtherValidator(): other control is not found in parent group');
      }
      secondaryControl.valueChanges.subscribe(() => {
        primaryControl.updateValueAndValidity();
      });
    }

    if (!secondaryControl) {
      return null;
    }

    if (type === 'equal') {
      if (secondaryControl.value === primaryControl.value) {
        return {
          nonMatchOther: true
        };
      }
    } else {
      if (secondaryControl.value !== primaryControl.value) {
        return {
          matchOther: true
        };
      }
    }
    return null;
  };
}

export function checkBlacklist(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if (regex.blacklistedEmail.test(control.value)) {
      return {
        blacklist: true
      };
    }
    return null;
  }
}
