import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogoScriptService {

  constructor() { }

  // used to hide page while Adobe Target removes Rocket Homes logo from logo block
  logoScript(win: any, doc: any, style: any, timeout=1500) {
    var STYLE_ID = 'at-body-style';
    function getParent() {
      return doc.getElementsByTagName('head')[0];
    }
    function addStyle(parent: any, id: any, def: any) {
      if (!parent) {
        return;
      }
      var style = doc.createElement('style');
      style.id = id;
      style.innerHTML = def;
      parent.appendChild(style);
    }
    function removeStyle(parent: any, id: any) {
      if (!parent) {
        return;
      }
      var style = doc.getElementById(id);
      if (!style) {
        return;
      }
      parent.removeChild(style);
    }
    addStyle(getParent(), STYLE_ID, style);
    setTimeout(function() {
      removeStyle(getParent(), STYLE_ID);
    }, timeout);
  }
}
