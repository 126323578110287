<div class="column">
    <ng-content></ng-content>
    <div class="bottomLinks">
        <p class="rkt-Body-18"><a *ngIf="talk" 
            id="talk" 
            class="bottomLinks standalone" 
            routerLink="/talk-to-us"
            data-analytics-click="Talk to Us"
            rktLink
            >
            Need help? Talk to us
        </a></p>
        <p class="rkt-Body-18"><a *ngIf="terms" 
            id="terms" 
            class="bottomLinks standalone" 
            routerLink="/terms-of-use"
            data-analytics-click="Terms Of Use"
            target="blank"
            rktLink
            >
            Terms of use
        </a></p>
        <p class="rkt-Body-18"><a *ngIf="privacy" 
            id="privacy" 
            class="bottomLinks standalone" 
            routerLink="/privacy-policy"
            data-analytics-click="Privacy Policy"
            target="blank"
            rktLink
            >
            Privacy policy
        </a></p>
        <p class="rkt-Body-18"><a *ngIf="doNotSell" 
            id="doNotSell" 
            class="bottomLinks standalone" 
            routerLink="/do-not-sell-my-personal-information"
            data-analytics-click="Do Not Sell My Personal Information"
            target="blank"
            rktLink
            >
            Do not sell or share my personal information
        </a></p>
    </div>
</div>

<app-tos *ngIf="displayTOS" (closed)="toggleTOS()"></app-tos>
