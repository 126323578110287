import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class RedirectGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (window.location.href.includes('localhost')) {
      window.location.href = 'http://localhost:4200/#/talk-to-us';
    } else
    if (window.location.href.includes('beta') || window.location.href.includes('test')) {
      window.location.href = 'https://dashboard.beta.rocketmortgage.com';
    } else {
      window.location.href = 'https://dashboard.rocketmortgage.com';
    }
      return true;

  }
}