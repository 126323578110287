import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class PostTokenService {

    private options = {
        headers: new HttpHeaders({
           'Content-Type': environment.headers.version,
            'Accept': environment.headers.version
        })
    }

    constructor(private http: HttpClient) { }

    postTokenCreate(token:string) {
        const tokenObj = { token: token };

        return this.http.post(environment.apiConsumerBaseV2 + '/account' + '/change-username/claim', tokenObj, this.options);
    }
}
