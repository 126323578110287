<div *ngIf="(cookies && sessionStorage);else errorOutlet">
  <router-outlet></router-outlet>
</div>

<ng-template #errorOutlet>
  <single-column [terms]="true" [talk]="true">

    <h1 class="rkt-TextAlign--center rkt-Heading-48 rkt-Heading-48--responsive"><img src="/assets/img/logo/MagicO.svg" id="horseShoeLogo" alt="Rocket Logo"></h1>
    <p class="rkt-TextAlign--center rkt-Heading-36 rkt-Heading-36--responsive">Rocket Account</p>
    <hr>

    <div class="cookie-message alert alert-error">
      <p>Rocket Account doesn’t work with your current browser settings. Your browser’s local storage, cookies, or both
        are disabled. Please enable cookies or your local storage to use this site, or try using a different web
        browser.</p>
    </div>
  </single-column>
</ng-template>
