import { settingsValidation } from '@splitsoftware/splitio-commons/esm/utils/settingsValidation/index';
import { defaults } from './defaults';
import { validateRuntime } from '@splitsoftware/splitio-commons/esm/utils/settingsValidation/runtime';
import { validateStorageCS } from '@splitsoftware/splitio-commons/esm/utils/settingsValidation/storage/storageCS';
import { validatePluggableIntegrations } from '@splitsoftware/splitio-commons/esm/utils/settingsValidation/integrations/pluggable';
import { validateLogger } from '@splitsoftware/splitio-commons/esm/utils/settingsValidation/logger/pluggableLogger';
import { validateLocalhost } from '@splitsoftware/splitio-commons/esm/utils/settingsValidation/localhost/pluggable';
import { validateConsent } from '@splitsoftware/splitio-commons/esm/utils/settingsValidation/consent';
var params = {
  defaults: defaults,
  acceptKey: true,
  runtime: validateRuntime,
  storage: validateStorageCS,
  integrations: validatePluggableIntegrations,
  logger: validateLogger,
  localhost: validateLocalhost,
  consent: validateConsent
};
export function settingsFactory(config) {
  return settingsValidation(config, params);
}