import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { UtilModule } from './util/util.module';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, UtilModule, CommonModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
    test = true;
    cookies = navigator.cookieEnabled;
    sessionStorage?: boolean;

    constructor() {}

    ngOnInit() {
        this.sessionStorage = this.lsTest();
    }

    lsTest() {
        const test = 'localStoreCheck';
        try {
            sessionStorage.setItem(test, test);
            sessionStorage.removeItem(test);
            return true;
        } catch (e) {
            return false;
        }
    }
}
